import Vue from 'vue'
import Vuex from 'vuex'
import {authentication} from "@/store/authentication.module";
import {inputFormStore} from "@/store/inputFormStore.module";
import {reference} from "@/store/reference.module";
import {adminReference} from "@/store/adminReference.module";
import {snackbars} from "@/store/snackbar.module";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        authentication,
        inputFormStore,
        reference,
        adminReference,
        snackbars
    },
})
