export const exportFurnitureData = {
    5: {
        titleThin: 'bloc',
        titleBold: 'basic',
        description: 'Комплект мебели, который отличается своей простотой и лаконичностью, без лишних деталей и сложных сочетаний. <br/>' +
            'Идеально впишется в любой интерьер. Доступен в светлом и тёмном исполнении.',
        images: ["/img/bloc/crossSales/5_1.png", "/img/bloc/crossSales/5_2.png", "/img/bloc/crossSales/5_3.png", "/img/bloc/crossSales/5_4.png"]
    },
    7: {
        titleThin: 'bloc',
        titleBold: 'country',
        description: 'Комплект мебели, сочетающий в себе свежие дизайнерские решения и высокий уровень функциональности. <br/>' +
            'Ярко выраженная текстура натурального дуба добавляет глубины и притягательности комплекту.',
        images: ["/img/bloc/crossSales/7_1.png", "/img/bloc/crossSales/7_2.png", "/img/bloc/crossSales/7_3.png", "/img/bloc/crossSales/7_4.png"]
    },
    6: {
        titleThin: 'bloc',
        titleBold: 'scandy',
        description: 'Комплект мебели, выполненной в скандинавском стиле с использованием природных оттенков дерева в сочетании с эмалью для корпусной мебели и натуральных тканей для мягкой мебели и штор. Доступен в светлом и темном исполнении.',
        images: ["/img/bloc/crossSales/6_1.png", "/img/bloc/crossSales/6_2.png", "/img/bloc/crossSales/6_3.png", "/img/bloc/crossSales/6_4.png"]
    },
    8: {
        titleThin: 'bloc',
        titleBold: 'modern',
        description: 'Комплект мебели, отличающейся органичным сочетанием дерева и покрытых эмалью элементов,которые сливаются в единое целое, создавая элегантный современный интерьер.',
        images: ["/img/bloc/crossSales/8_1.png", "/img/bloc/crossSales/8_2.png", "/img/bloc/crossSales/8_3.png", "/img/bloc/crossSales/8_4.png"]
    },
}

export const exportMiniFurnitureData = {
    10: {
        titleThin: 'кухня',
        titleBold: 'bloc',
        description: 'В каждую квартиру нужна своя кухня.<br/>Мы подобрали для вас самые актуальные и стильные решения только из высококачественных материалов.',
        images: [
            {src: "/img/bloc/crossSales/10_1.png", name: "basic", serviceId: 19},
            {src: "/img/bloc/crossSales/10_2.png", name: "country", serviceId: 27},
            {src: "/img/bloc/crossSales/10_3.png", name: "scandi", serviceId: 23},
            {src: "/img/bloc/crossSales/10_4.png", name: "modern", serviceId: 10}
        ]
    },
    11: {
        titleThin: 'гостиная',
        titleBold: 'bloc',
        description: 'Комплект, который соединил все самые нужные предметы интерьера в одном месте:<br/> диван, стол, стулья, которые никого не оставят равнодушным.',
        images: [
            {src: "/img/bloc/crossSales/11_1.png", name: "basic", serviceId: 20},
            {src: "/img/bloc/crossSales/11_2.png", name: "country", serviceId: 28},
            {src: "/img/bloc/crossSales/11_3.png", name: "scandi", serviceId: 24},
            {src: "/img/bloc/crossSales/11_4.png", name: "modern", serviceId: 11}
        ]
    },
    12: {
        titleThin: 'спальня',
        titleBold: 'bloc',
        description: 'Создайте свое идеальное место комфорта и уюта, выбрав одну из четырех вариантов спальни от BLOC.',
        images: [
            {src: "/img/bloc/crossSales/12_1.png", name: "basic", serviceId: 21},
            {src: "/img/bloc/crossSales/12_2.png", name: "country", serviceId: 29},
            {src: "/img/bloc/crossSales/12_3.png", name: "scandi", serviceId: 25},
            {src: "/img/bloc/crossSales/12_4.png", name: "modern", serviceId: 12}
        ]
    },
    13: {
        titleThin: 'детская',
        titleBold: 'bloc',
        description: '4 коллекции для детской комнаты на любой вкус. От простой и лаконичной до ультра современной, <br/>чтобы каждый смог найти свою единственную.',
        images: [
            {src: "/img/bloc/crossSales/13_1.png", name: "basic", serviceId: 22},
            {src: "/img/bloc/crossSales/13_2.png", name: "country", serviceId: 30},
            {src: "/img/bloc/crossSales/13_3.png", name: "scandi", serviceId: 26},
            {src: "/img/bloc/crossSales/13_4.png", name: "modern", serviceId: 13}
        ]
    },
}

export const certPartners = [
    {
        src: "/img/bloc/logo/hoff.png",
        description: "Полностью укомплектовать квартиру текстилем, посудой, декоративными аксессуарами и предметами обихода в ипотеку поможет сеть магазинов товаров для дома Hoff и BLOC."
    },{
        src: "/img/bloc/logo/sberDevices.png",
        description: "Умная квартира - главный тренд последнего десятилетия, который теперь доступен каждому. Благодаря партнерской программе BLOC и SBERDEVICES любой желающий может включить «умный дом» в ипотеку."
    },{
        src: "/img/bloc/logo/lerua.png",
        description: "Для тех, кто так давно мечтал об индивидуальном дизайн-проекте собственной квартиры, появилось уникальное предложение от BLOC и Леруа Мерлен исполнить эту мечту прямо сейчас, включив строительные материалы в ипотеку."
    },{
        src: "/img/bloc/logo/mvideo.png",
        description: "Любую квартиру можно дополнить техникой известных мировых брендов, представленных в крупнейшей сети магазинов бытовой техники и электроники М.Видео"
    },
]

export const housingComplexes = [
    {id: 1, name: "ЖК «Зеленая территория»"}
]
export const roomsCount = [
    {id: 4, name: "Студия"},
    {id: 1, name: "1кк"},
    {id: 2, name: "2кк"},
    {id: 3, name: "3кк"},
]