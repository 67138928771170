<template>
  <div>
    <v-snackbar
      v-for="(snackbar, index) of snackbars.filter(el=>el.showing)"
      :key="snackbar.content + Math.random()"
      :value="snackbar.showing"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      @input="input(snackbar)"
      multi-line
      bottom
      right
      :style="`bottom: ${86 + (index * 76) + 8}px`"
    >
      <span v-html="snackbar.content"></span>

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          v-bind="attrs"
          @click="close(snackbar)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

export default {
  name: 'TheSnackbar',
  data() {
    return {}
  },

  computed: {
    snackbars () {
      return this.$store.getters['snackbars/getSnackbars']
    }
  },
  methods:{
    input(snackbar){
      this.$store.dispatch('snackbars/delSnackbar', snackbar)
    },
    close(snackbar){
      this.$store.dispatch('snackbars/delSnackbar', snackbar)
    }
  }
}
</script>
