import {authHeader} from "@/_helpers";

export function get(url, params) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        params: params
    };

    return fetch(`${process.env.VUE_APP_BASE_URL}${url}`, requestOptions).then(handleResponse);
}

export function post(url, params) {
    return handleRequest(url, params, 'POST');
}

export function put(url, params) {
    return handleRequest(url, params, 'PUT');
}

export function patch(url, params) {
    return handleRequest(url, params, 'PATCH');
}

export function remove(url, params) {
    return handleRequest(url, params, 'delete');
}

function handleRequest(url, params, method) {
    const requestOptions = {
        method: method,
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            ...authHeader()
        },
        body: JSON.stringify(params),
    };

    return fetch(`${process.env.VUE_APP_BASE_URL}${url}`, requestOptions).then(handleResponse);
}

// function handleNoContentResponse(response) {
//     console.log('handleNoContentResponse', response)
//     return response.json().then(data => {
//         if (!response.ok) {
//             const error = (data && data.detail) || response.statusText;
//             return Promise.reject({status: response.status, detail: error});
//         }
//
//         return Promise.resolve(data);
//     });
// }

function handleResponse(response) {
    console.log('handleResponse', response)
    if (response.status === 204) {
        return Promise.resolve()
    }
    if (response.status === 401) {
        // auto logout if 401 response returned from api
        localStorage.removeItem('jwt');
        console.log('clear jwt')
    }
    return response.json().then(data => {
        if (!response.ok) {
            const error = (data && data.detail) || response.statusText;
            return Promise.reject({status: response.status, detail: error});
        }
        return Promise.resolve(data);
    });

}