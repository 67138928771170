<template>
  <div class="service-mini-furniture-block" :class="{active}">
    <div class="service-mini-furniture-block-title">
      <div>{{ titleThin }}&nbsp;<strong>{{ titleBold }}</strong></div>
      <div class="service-mini-furniture-block-title-present">В ПОДАРОК Сертификат Bloc.Партнер</div>
    </div>
    <div class="service-mini-furniture-block-description" v-html="description"></div>
    <div class="service-mini-furniture-block-images">
      <div class="block-image" v-for="(image, index) of images" :key="index">
        <img :src="image.src" height="100%" width="100%" :alt="titleBold">
        <div class="image-name">
          <div>{{ image.name }}</div>
          <PaymentChip
              :payment="getPayment(image.serviceId)"
              :active="active"
              small
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentChip from "@/components/exportBlocks/PaymentChip.vue";

export default {
  name: "ServiceMiniFurnitureBlock",
  components: {PaymentChip},
  props: {
    titleThin: String,
    titleBold: String,
    description: String,
    active: Boolean,
    images: Array
  },
  computed: {
    blockPayment() {
      return this.$store.getters["inputFormStore/getBlockPayment"]
    },
  },
  methods:{
    getPayment(serviceId){
      return this.blockPayment[serviceId];
    }
  }
}
</script>

<style lang="scss">
.service-mini-furniture-block {
  height: 186px;
  padding: 10px 20px;
  display: grid;
  grid-template-rows: 26px auto 112px;
  gap: 10px;

  box-sizing: border-box;

  .service-mini-furniture-block-title {
    display: flex;
    font-size: 20px;
    text-transform: uppercase;

    div:first-child {
      flex-grow: 1;
    }

    .service-mini-furniture-block-title-present {
      font-size: 12px;
      text-transform: none;
    }
  }

  .service-mini-furniture-block-description {
    font-size: 8px;
    line-height: 10px;
  }

  .service-mini-furniture-block-images {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    .block-image {
      border-radius: 8px;
      height: 112px;
      display: flex;
      flex-direction: column;
      box-sizing: content-box;
      justify-content: space-between;

      img {
        height: 90px;
      }

      .image-name {
        height: 16px;
        display: flex;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
        justify-content: space-between;
        align-items: center;
      }
    }

  }


  &.active {
    background-color: black;
    color: white;
  }

}
</style>