<template>
  <div class="service-partner-block" :class="{active}">
    <div class="service-partner-block-column1">
      <div>
        <img src="/img/bloc/crossSales/16_1.png" width="100%" alt=""/>
      </div>
      <div class="service-partner-block-info">
        <div class="service-partner-block-info-title">
          <div>СЕРТИФИКАТ BLOC | ПАРТНЁР</div>
          <PaymentChip
              :payment="payment"
              large
              :active="active"
          />
        </div>
        <div class="service-partner-block-info-description">
          <p>Сертификаты на умный дом, бытовую технику,
            домашнюю утварь и декор от ведущих брендов
            доступны в ипотеку.</p>

          <p>Теперь не нужно торопиться с выбором. Активируйте сертификат после получения ключей
            от своей новой квартиры, определитесь с выбором
            и сделайте заказ в любом из магазинов-партнёров BLOC.</p>

          <p>Ассистент BLOC вам любезно в этом поможет.</p>
          <p>*Список партнёров постоянно пополняется.</p>
        </div>
      </div>
    </div>
    <div class="service-partner-block-column2">
      <BlocPartnerBlock
          v-for="(item, index) of certPartners"
          :key="index"
          :src="item.src"
          :description="item.description"
      />
    </div>
  </div>
</template>

<script>
import PaymentChip from "@/components/exportBlocks/PaymentChip.vue";
import {certPartners} from "@/resource/resources";
import BlocPartnerBlock from "@/components/exportBlocks/certificatePage/BlocPartnerBlock.vue";

export default {
  name: "ServicePartnerBlock",
  components: {BlocPartnerBlock, PaymentChip},
  props:{
    active: Boolean,
    payment: [String, Number]
  },
  computed: {
    certPartners() {
      return certPartners
    }
  }
}
</script>

<style lang="scss">
.service-partner-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 10px 20px;

  .service-partner-block-info {
    font-size: 8px;
    line-height: 10px;
    padding: 10px 10px 1px 10px;
    border-radius: 8px;
    background-color: #eee;
    color: black;

    .service-partner-block-info-title {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 10px;

      div:first-child {
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
      }

      div:last-child {
        align-self: center;
        justify-self: end;
      }
    }

    .service-partner-block-info-description {
      p {
        margin-bottom: 10px;
      }
    }
  }

  &.active{
    .service-partner-block-info{
      background-color: black;
      color: white;
    }
  }

  .service-partner-block-column2{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

}
</style>