<template>
  <div>

    <div class="export-page" id="export-page">
      <div class="export-page-main">
        <div class="export-page-main-title">
          <v-img
              alt="BLOC Logo"
              class="shrink mr-2 logo"
              contain
              width="120"
              :src="require('@/assets/bloc/logo_bloc_black.png')"
          />
          <div class="vertical-divider"></div>
          <img
              alt="FLAGMAN Logo"
              class="shrink mr-2"
              height="26"
              :src="'/img/flagman/logo_flagman.svg'"
          />
        </div>
        <div class="main-image-block">
          <img :src="'/img/flagman/mainphoto.webp'">
          <div class="black-line"></div>
          <div class="room-plan">
            <img :src="roomPlan">
          </div>
          <div class="link-qrcode">

          </div>
        </div>
        <div class="main-info">
          <div class="main-info-block">
            <ul>
              <li>
                <div>Жилой комплекс</div>
                <div>{{ dotLine }}</div>
                <div>{{ housingComplex }}</div>
              </li>
              <li>
                <div>Площадь</div>
                <div>{{ dotLine }}</div>
                <div>{{ inputData.square }} м<sup>2</sup></div>
              </li>
              <li>
                <div>Количество комнат</div>
                <div>{{ dotLine }}</div>
                <div>{{ roomCount }}</div>
              </li>
              <li>
                <div>Стоимость жилья</div>
                <div>{{ dotLine }}</div>
                <div>{{ formattedValue(coast) }} &#8381;</div>
              </li>
              <li>
                <div>Процентная ставка</div>
                <div>{{ dotLine }}</div>
                <div>{{ inputData.bet }} %</div>
              </li>
              <li>
                <div>Первоначальный взнос</div>
                <div>{{ dotLine }}</div>
                <div>{{ formattedValue(inputData.deposit) }} &#8381;</div>
              </li>
              <li v-if="addSquareId">
                <div>Дополнительно</div>
                <div>{{ dotLine }}</div>
                <div>{{ addSquare }}</div>
              </li>
              <li v-if="presentId">
                <div>Подарок</div>
                <div>{{ dotLine }}</div>
                <div>{{ presentName }}</div>
              </li>
              <li v-for="addPayment of blockAddPayments"
                  :key="addPayment.id"
              >
                <div>{{ getServiceTypeName(addPayment) }}
                </div>
                <div>{{ dotLine }}</div>
                <div>+ {{ formattedValue(addPayment.payment) }} &#8381; ({{ getServiceName(addPayment) }})</div>
              </li>
              <li>
                <div>Ежемесячный платеж
                </div>
                <div>{{ dotLine }}</div>
                <div>{{ formattedValue(inputData.payment) }} &#8381;</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="cross-sales">
          <CrossSalesCard
              v-for="crossSale of filteredCrossSales"
              :key="crossSale.service_id"
              :image-path="getCrossSalesImage(crossSale.service_id)"
              :title="crossSale.title"
              :subtitle="crossSale.sub_title"
              :payment="getPayment(crossSale.service_id)"
          />
          <CrossSalesCard
              v-if="filteredCrossSales.length===2"
              :image-path="getCrossSalesImage(-2)"
              title="Полное КП можно посмотреть по QR-коду"
              two-column
          />
          <CrossSalesCard
              v-if="filteredCrossSales.length===3"
              :image-path="getCrossSalesImage(-1)"
              title="Полное КП можно посмотреть по QR-коду"
          />
        </div>
      </div>
      <div class="export-page-repair pdf-page-break">
        <component
            v-for="repairId of repairBlocksSequence"
            :key="repairId"
            :is="repairBlocks[repairId]"
            :payment="getPayment(repairId)"
        />
      </div>
      <div class="export-page-furniture pdf-page-break">
        <ServiceFurnitureBlock
            v-for="key of exportFurnitureDataKeys"
            :key="key"
            v-bind="getFurnitureData(key)"
            :active="activeFurnitureBlock===key"
            :payment="getPayment(key)"
        />
      </div>
      <div class="pdf-page-break">
        <ServiceMiniFurnitureBlock
            v-for="key of exportMiniFurnitureDataKeys"
            :key="key"
            v-bind="getMiniFurnitureData(key)"
            :active="activeFurnitureBlock===key"
        />
        <ServiceLongFurnitureBlock
            title="Климатическая система"
            image="/img/bloc/crossSales/14_1.png"
            :active="activeFurnitureBlock===14"
            :payment="getPayment(14)"
        />
      </div>
      <div class="export-page-certificate pdf-page-break">
        <ServicePartnerBlock
            :active="true"
            :payment="getPayment(16)"
        />
        <BlocDesignBlock
            :active="activeCertificateBlock===15"
            :payment="getPayment(15)"
        />
      </div>
    </div>
    <v-btn @click="save">SAVE</v-btn>
  </div>
</template>

<script>
import {exportFurnitureData, exportMiniFurnitureData, housingComplexes, roomsCount} from "@/resource/resources";
import CrossSalesCard from "@/components/exportBlocks/CrossSalesCard.vue";
import {pmt} from "@/resource/pmt";
import ServiceStartBlock from "@/components/exportBlocks/repairPage/ServiceStartBlock.vue";
import ServiceComfortBlock from "@/components/exportBlocks/repairPage/ServiceComfortBlock.vue";
import ServiceDesignBlock from "@/components/exportBlocks/repairPage/ServiceDesignBlock.vue";
import ServiceFurnitureBlock from "@/components/exportBlocks/furniturePage/ServiceFurnitureBlock.vue";
import ServiceMiniFurnitureBlock from "@/components/exportBlocks/furniturePage/ServiceMiniFurnitureBlock.vue";
import ServiceLongFurnitureBlock from "@/components/exportBlocks/furniturePage/ServiceLongFurnitureBlock.vue";
import ServicePartnerBlock from "@/components/exportBlocks/certificatePage/ServicePartnerBlock.vue";
import BlocDesignBlock from "@/components/exportBlocks/certificatePage/BlocDesignBlock.vue";
import html2pdf from "html2pdf.js/src";

export default {
  name: "MainExportPage",
  components: {
    BlocDesignBlock,
    ServicePartnerBlock,
    ServiceLongFurnitureBlock,
    ServiceMiniFurnitureBlock,
    ServiceFurnitureBlock,
    ServiceDesignBlock,
    ServiceComfortBlock,
    ServiceStartBlock,
    CrossSalesCard
  },
  props: {},
  data() {
    return {
      dotLine: '..........................................................',
      BLOC_CERT_SERVICE_ID: 16,
      REPAIR_TYPE_ID: 1,
      FURNITURE_TYPE_ID: 2,
      CERT_TYPE_ID: 3,
      repairBlocks: {
        1: 'ServiceStartBlock',
        2: 'ServiceComfortBlock',
        3: 'ServiceDesignBlock'
      },
    }
  },
  computed: {
    inputData() {
      return this.$store.getters["inputFormStore/getInputData"]
    },
    housingComplex() {
      return housingComplexes.find(el => el.id === this.inputData.housingComplex)?.name ?? ''
    },
    roomCount() {
      return roomsCount.find(el => el.id === this.inputData.roomCount)?.name ?? ''
    },
    coast() {
      return this.inputData.price * this.inputData.square
    },
    addSquareId() {
      return this.inputData.addSquare
    },
    addSquare() {
      //TODO: проверить
      return this.addSquareId ? this.$store.getters['reference/getPresents'].find(el => el.id === this.addSquareId).name : null
    },
    presentId() {
      return this.inputData.presentId
    },
    present() {
      return this.presentId ? this.$store.getters['reference/getPresents'].find(el => el.id === this.presentId) : null
    },
    presentName() {
      return this.present ? this.present.name : null
    },
    roomPlans() {
      return this.$store.getters["reference/getHousingLiterPlans"] ?? []
    },
    roomPlan() {
      return this.roomPlans.find(el => el.id === this.inputData.housingPlan)?.picture ?? ''
    },
    blockAddPayments() {
      return (this.$store.getters["inputFormStore/getBlockAddPayments"] ?? []).filter(el => el.id)
    },
    services() {
      return this.$store.getters["reference/getService"]
    },
    crossSales() {
      return this.$store.getters["inputFormStore/getCrossSales"] ?? []
    },
    blockPayment() {
      return this.$store.getters["inputFormStore/getBlockPayment"]
    },
    isBuyCertificates(){
      const block = this.blockAddPayments.find(el => el.blockId === this.CERT_TYPE_ID)
      return block?.id && block?.payment && block?.id === 16
    },
    filteredCrossSales() {
      const presentServiceId = this.services.find(el => el.present_id === this.presentId)?.id
      return this.crossSales.filter(el => el.service_id !== presentServiceId &&
          (this.isBuyCertificates ? el.service_id !== 16 : true)
      )
    },
    activeRepairBlock() {
      const bloc = this.blockAddPayments.find(el => el.blockId === this.REPAIR_TYPE_ID)
      return bloc?.id && bloc?.payment ? bloc.id : null
    },
    repairBlocksSequence() {
      if (this.activeRepairBlock) {
        return [this.activeRepairBlock, ...Object.keys(this.repairBlocks)
            .filter(key => +key !== this.activeRepairBlock)
            .sort()
            .map(el => +el)
        ]
      }
      return Object.keys(this.repairBlocks).sort().map(el => +el)
    },
    activeFurnitureBlock() {
      //const bloc = this.blockAddPayments.find(el => el.blockId === this.FURNITURE_TYPE_ID)
      //return bloc?.id && bloc?.payment ? bloc.id : null
      return null
    },
    activeCertificateBlock() {
      //const bloc = this.blockAddPayments.find(el => el.blockId === this.CERT_TYPE_ID)
      //return bloc?.id && bloc?.payment ? bloc.id : null
      return null
    },
    exportFurnitureDataKeys() {
      return Object.keys(exportFurnitureData ?? {}).map(el => +el)
    },
    exportMiniFurnitureDataKeys() {
      return Object.keys(exportMiniFurnitureData ?? {}).map(el => +el)
    }
  },
  methods: {
    formatNumber(num) {
      return new Intl.NumberFormat("ru", {style: "decimal"}).format(num)
    },
    formattedValue(value) {
      return (Number.isFinite(value) ? this.formatNumber(value) : 0)
    },
    getServiceTypeName(addPayment) {
      return this.services.find(el => el.id === addPayment.id)?.service_type_name
    },
    getServiceName(addPayment) {
      return this.services.find(el => el.id === addPayment.id).name
    },
    getCrossSalesImage(serviceId) {
      return `/img/bloc/service/${serviceId}.png`
    },
    getPayment(service_id) {
      if (service_id === this.BLOC_CERT_SERVICE_ID && !this.blockPayment[service_id]) {
        return this.getPaymentCertificate()
      }
      return this.blockPayment[service_id]
    },
    getPaymentCertificate() {
      var ratePerPeriod = this.inputData.bet / 12 * 0.01,              // Процентная ставка по ссуде
          numberOfPayments = this.inputData.numberOfPayments * 12,   // Срок, мес.
          presentValue = 40000;                             // Стоимость
      var value = -pmt(ratePerPeriod, numberOfPayments, presentValue, null, null);
      return value ? +value.toFixed(2) : null
    },
    getFurnitureData(serviceId) {
      return exportFurnitureData[serviceId]
    },
    getMiniFurnitureData(serviceId) {
      return exportMiniFurnitureData[serviceId]
    },
    save() {
      var element = document.getElementById('export-page');
      const opt = {
        margin: 0,
        filename: `bloc-kp.pdf`,
        image: {type: 'jpeg', quality: 1.0},
        html2canvas: {scale: 3},
        jsPDF: {unit: 'px', format: [595, 842], orientation: 'portrait'},
        pagebreak: {mode: 'css', before: 'pdf-page-break'}
      };
      html2pdf().set(opt).from(element).save().then(() => {
        this.$emit('close')
      });
    }
  }
}
</script>

<style lang="scss">
.export-page {

}

.export-page > div {
  /* A4 - 150dpi
width: 1240px;
height: 1754px;
*/
  /* A4 - 72dpi*/
  width: 595px;
  height: 840px;
  //border: solid 1px black;
  box-sizing: border-box;
  font-family: "TT Norms", serif;
  position: relative;

  &:after {
    content: '*Предоставляемый расчет является предварительным и носит информационных характер';
    position: absolute;
    bottom: 0;
    left: 20px;
    font-size: 8px;
  }
}

.export-page-certificate {
  padding-top: 8px;
}

.export-page-main {
  position: relative;
  display: grid;
  grid-template-rows: 69px minmax(240px, 448px) auto 248px;

  .export-page-main-title {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .vertical-divider {
    border-left: 2px solid black;
    height: 36px;
    border-radius: 1px;
    margin: 0 12px;
  }

  .main-image-block {
    position: relative;
    padding: 0 20px 20px 20px;

    img {
      height: 100%;
      width: 100%;
    }

    .room-plan {
      width: 128px;
      position: absolute;
      top: 20px;
      right: 40px;

      img {
        border-radius: 8px;
      }
    }

    .link-qrcode {
      position: absolute;
      bottom: 40px;
      left: 40px;
      width: 96px;
      height: 96px;
      background-color: #FFFFFF;
      background-image: url("@/assets/bloc/qr-code.png");
      background-size: contain;
      border-radius: 8px;
    }
  }

  .main-info {
    background-color: black;

    .main-info-block {
      border: 1px solid white;
      margin: 20px;
      font-size: 9px;
      line-height: 12px;

      ul {
        list-style: none;
        margin: 16px 0;
        color: white;

        li {
          display: grid;
          grid-template-columns: 1fr auto 1fr;
          padding: 2px 0;
          gap: 6px;
          align-items: center;

          div:first-child {
            justify-self: end;
            padding-right: 4px;
          }
        }
      }
    }
  }

  .cross-sales {
    margin: 20px;
    display: flex;
    gap: 20px;
  }
}

</style>