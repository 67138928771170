import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';
//import en from 'vuetify/es5/locale/en';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: colors.shades.black,
                secondary: colors.grey.darken3,
                accent: colors.indigo.base,
                success: colors.green.darken3
            },
        },
    },
    // lang: {
    // locales: { en },
    // current: 'en',
    // // Modify the default messages
    // t: (key) => {
    //   if (key === '$vuetify.noDataText') return 'Нет данных';
    //   console.log('t', key, en)
    //   return en[key] ? en[key] : key;
    // },
  // },
});
