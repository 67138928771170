<template>
  <div class="service-design-block" :class="{active}">
    <div class="service-design-block-column column-one">
      <img src="/img/bloc/crossSales/3_1.png" height="100%" width="100%" alt="design"/>
    </div>
    <div class="service-design-block-column column-two">
      <div>
        <img src="/img/bloc/crossSales/3_2.png" height="100%" width="100%" alt="design"/>
      </div>
      <div>
        <img src="/img/bloc/crossSales/3_3.png" height="100%" width="100%" alt="design"/>
      </div>
      <div>
        <img src="/img/bloc/crossSales/3_4.png" height="100%" width="100%" alt="design"/>
      </div>
      <div>
        <img src="/img/bloc/crossSales/3_5.png" height="100%" width="100%" alt="design"/>
      </div>
    </div>
    <div class="service-design-block-column column-three">
      <h1>BLOC DESIGN</h1>
      <p>Дизайнерский ремонт, выполненный из материалов премиальных брендов и согласно последним тендециям в сфере
        ремонтно-отделочных работ жилых помещений.
        <ul>
          <li>Ламинат by Quick Step 33 класс intense</li>
          <li>Плинтус из дюрополимера под покраску в цвет стены</li>
          <li>Окраска стен краской Tikkurila</li>
          <li>Крупноформатный керамогранит Kerama Marazzi</li>
          <li>Двери (МДФ + эмаль)</li>
          <li>Встроенная сантехника</li>
          <li>Теплые пол и стена в с/у</li>
        </ul>
      </p>
      <PaymentChip
          :payment="payment"
          :active="active"
          large
      />
    </div>
  </div>
</template>

<script>
import PaymentChip from "@/components/exportBlocks/PaymentChip.vue";

export default {
  name: "ServiceDesignBlock",
  components: {PaymentChip},
  props: {
    active: Boolean,
    payment: [Number, String]
  },
  data() {
    return {}
  }
}
</script>


<style lang="scss">
.service-design-block {
  width: 595px;
  height: 240px;
  position: relative;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  padding: 20px;
  gap: 10px;
  box-sizing: border-box;
  color: black;
  background-color: white;

  &.active {
    color: white;
    background-color: black;
  }

  .service-design-block-column {
    p {
      margin-bottom: 16px;
    }

    img {
      border-radius: 6px;
    }

    &.column-two {
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 134px 58px;
      gap: 10px;

    }

    &.column-three {
      h1 {
        font-size: 18px;
        margin-bottom: 12px;
      }

      p {
        font-size: 8px;
        line-height: 9px;

        ul {
          margin-top: 4px;
        }
      }
    }
  }

}


</style>