import Vue from "vue";

export const inputFormStore = {
    namespaced: true,
    state: {
        totalPayment: null,
        blockAddPayments: [],
        inputData: {
            preApprovedAmount: null,
            isPreApprovedAmount: false,
            housingComplex: 1,
            housingLiter: null,
            housingPlan: null,
            price: null,
            square: null,
            roomCount: null,
            bet: null,
            deposit: null,
            depositPerc: 0,
            withDeposit: false,
            payment: null,
            numberOfPayments: null,
            addSquare: null,
            addSquarePrice: null,
            presentId: null
        },
        blockPayment: {},
        crossSales: []
    },
    getters: {
        getInputData: (state) => state.inputData,
        getRoomCount: (state) => state.inputData.roomCount,
        getTotalPayment: (state) => state.totalPayment,
        getBlockAddPayments: (state) => state.blockAddPayments,
        getFullCoast: (state) => (state.inputData.square ?? 0) * (state.inputData.price ?? 0)
            + (state.inputData.addSquare != state.inputData.presentId ? state.inputData.addSquarePrice || 0 : 0),
        getBlockPayment: (state) => state.blockPayment,
        getCrossSales: (state) => state.crossSales,
        getBlockPaymentById: state => {
            return (id) => {
                return state.blockPayment[id]
            }
        },
    },
    mutations: {
        SET_INPUT_DATA: (state, inputData) => {
            Vue.set(state, 'inputData', inputData)
        },
        SET_TOTAL_PAYMENT: (state, value) => {
            state.totalPayment = +value
        },
        ADD_BLOCK_ADD_PAYMENT: (state, block) => {
            const oldBlock = state.blockAddPayments.find(el => el.blockId === block.blockId)
            if (oldBlock) {
                oldBlock.id = block.id
                oldBlock.payment = +block.payment
            } else {
                state.blockAddPayments.push(block)
            }
        },
        CLEAR(state) {
            state.inputData = {
                preApprovedAmount: null,
                isPreApprovedAmount: false,
                housingComplex: 1,
                housingLiter: null,
                housingPlan: null,
                price: null,
                square: null,
                roomCount: null,
                bet: null,
                deposit: null,
                depositPerc: 0,
                withDeposit: false,
                payment: null,
                numberOfPayments: null,
                addSquare: null,
                addSquarePrice: null,
                presentId: null
            }
            state.totalPayment = null
            state.blockPayment = {}
            state.blockAddPayments = []
        },
        ADD_BLOCK_PAYMENT(state, payload) {
            Vue.set(state.blockPayment, payload.id, payload.value)
        },
        SET_CROSS_SALES(state, payload) {
            state.crossSales = payload
        }
    },
    actions: {
        setInputData({commit}, inputData) {
            commit("SET_INPUT_DATA", inputData);
        },
        setTotalPayment({commit}, value) {
            commit("SET_TOTAL_PAYMENT", value);
        },
        /**
         * @param commit
         * @param {Object} block - {blockId, payment}
         * */
        addBlockAddPayment({commit}, block) {
            commit("ADD_BLOCK_ADD_PAYMENT", block);
        },
        clear({commit}) {
            commit("CLEAR")
        },
        addBlockPayment({commit}, payload) {
            commit("ADD_BLOCK_PAYMENT", payload)
        },
        setCrossSales({commit}, payload) {
            commit("SET_CROSS_SALES", payload)
        }
    },
}