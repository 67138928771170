<template>
  <div class="service-furniture-block" :class="{active}">
    <div class="service-furniture-block-title">
      <div>{{ titleThin }}&nbsp;<strong>{{ titleBold }}</strong></div>
      <PaymentChip
          :payment="payment"
          :active="active"
          large
      />
    </div>
    <div class="service-furniture-block-description" v-html="description"></div>
    <div class="service-furniture-block-images">
      <div v-for="(image, index) of images" :key="index">
        <img :src="image" height="100%" width="100%" :alt="titleBold"/>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentChip from "@/components/exportBlocks/PaymentChip.vue";

export default {
  name: "ServiceFurnitureBlock",
  components: {PaymentChip},
  props: {
    titleThin: String,
    titleBold: String,
    description: String,
    payment: [String, Number],
    active: Boolean,
    images: Array
  },
  computed: {}
}
</script>

<style lang="scss">
.service-furniture-block {
  height: 176px;
  display: grid;
  grid-template-rows: 26px auto 100px;
  gap: 5px;
  padding: 10px 20px;
  box-sizing: border-box;

  .service-furniture-block-title {
    display: flex;
    font-size: 20px;
    text-transform: uppercase;

    div:first-child {
      flex-grow: 1;
    }
  }

  .service-furniture-block-description {
    font-size: 8px;
    line-height: 10px;
  }

  .service-furniture-block-images {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    div {
      border-radius: 8px;
      img{
        height: 100px;
      }
    }

    div:last-child {
      display: none;
    }
  }

  &.active {
    background-color: black;
    color: white;
    height: 296px;
    grid-template-rows: 26px auto 220px;
    padding: 5px 20px;

    .service-furniture-block-images {
      width: 364px;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 100px);
      gap: 10px;

      div:last-child {
        display: block;
      }
    }
  }
}
</style>