<template>
  <div class="service-long-furniture-block" :class="{active}">
    <div class="service-long-furniture-block-image">
      <img :src="image" height="100%" width="100%" alt=""/>
    </div>
    <div class="service-long-furniture-info">
      <div>{{ title }}</div>
      <PaymentChip
          :payment="payment"
          :active="active"
          large
      />
    </div>
  </div>
</template>

<script>
import PaymentChip from "@/components/exportBlocks/PaymentChip.vue";

export default {
  name: "ServiceLongFurnitureBlock",
  components: {PaymentChip},
  props: {
    title: String,
    payment: [String, Number],
    active: Boolean,
    image: String
  },
}
</script>

<style scoped lang="scss">
.service-long-furniture-block {
  height: 76px;
  display: grid;
  grid-template-columns: 315px 220px;
  gap: 20px;
  padding: 3px 20px;
  box-sizing: border-box;

  .service-long-furniture-block-image {
    height: 71px;

    img {
      object-fit: contain;
    }
  }

  .service-long-furniture-info {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 10px 20px;

    div:first-child {
      flex-grow: 1;
    }
  }

  &.active {
    .service-long-furniture-info {
      background-color: black;
      color: white;
    }
  }
}
</style>