<template>
  <div style="height: 100%">
    <div :class="{'loading-reference':referenceLoading}" class="home-page">
      <div class="loading-reference-circular" v-if="referenceLoading">
        <v-progress-circular
            :size="70"
            :width="7"
            color="white"
            indeterminate
        ></v-progress-circular>
      </div>
      <template v-else>
        <v-container class="page-container">

          <h1 class="white--text">ИПОТЕЧНЫЙ КАЛЬКУЛЯТОР</h1>
          <InputForm class="mt-4" @validate="(v) => this.valid=v"/>
          <ServicesCard
              class="mt-4"
              :title="repairBlockTitle"
              :id="REPAIR_TYPE_ID"
              :block-data="repairBlockData"
              :coast-data="serviceCost"
              @detail="detailHandler"
          />
          <ServicesCard
              class="mt-4"
              :title="furnitureBlockTitle"
              :id="FURNITURE_TYPE_ID"
              :block-data="furnitureBlockData"
              :coast-data="serviceCost"
              @detail="detailHandler"
              with-service
          />
          <CertificateCard
              class="mt-4"
              :title="certificateBlockTitle"
              :id="CERTIFICATE_TYPE_ID"
              :block-data="certificateBlockData"
              :coast-data="serviceCost"
              @detail="detailHandler"
          />
        </v-container>
        <v-container>
          <v-col
              cols="12"
              class="d-flex align-center"
          >
            <v-spacer></v-spacer>
            <p class="pr-6 mb-0"><strong>Итоговый ежемесячный платеж: </strong>{{ totalPayment }} руб./мес.</p>
            <v-btn color="primary"
                   :disabled="!valid"
                   @click="save"
            >
              Скачать КП
            </v-btn>
          </v-col>
        </v-container>
      </template>
    </div>

    <v-dialog
        v-model="showRepairDialog"
        :width="tourType==='img' ? '70%' : '100%'"
        :fullscreen="fullscreenDialog"
        class="card-description-dialog"
    >
      <v-card dark class="card-description-dialog">
        <v-card-title class="d-flex">{{ currentServiceDetail ? currentServiceDetail.title : null }}
          <v-spacer/>
          <v-btn icon class="mr-2" @click="fullscreenDialog=!fullscreenDialog">
            <v-icon v-text="fullscreenDialogIcon"></v-icon>
          </v-btn>
          <v-btn icon @click="showRepairDialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="viewer" :class="{fullscreen: fullscreenDialog}">
          <div class="column1">
            <template v-if="tourType==='iframe'">
              <iframe v-if="currentTour"
                      title="PDF" width="100%" height="100%"
                      :src="currentServiceDetail ? currentServiceDetail.tour : null"></iframe>
            </template>
            <img
                class="card-description-image"
                v-if="tourType==='img'"
                :src="getImage(currentTour)"
                alt=""
            >
          </div>
          <div class="column2">
            <h3 class="mb-3">{{ currentServiceDetail ? currentServiceDetail.description : null }}</h3>
            <div class="scrollable">
              <div class="viewer-info-wrapper">
                <div v-html="currentServiceDetails">
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="showKP"
        width="100%"
    >
      <v-card>
        <v-card-title>Коммерческое предложение (предпросмотр)</v-card-title>
        <v-card-text>
          <MainExportPage
              @close="showKP=false"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import InputForm from "@/components/InputForm.vue";
import ServicesCard from "@/components/ServicesCard.vue";
import MainExportPage from "@/components/exportBlocks/MainExportPage.vue";
import {API_PATH} from "@/_services/API_PATH";
import {post} from "@/_services/api";
import CertificateCard from "@/components/CertificateCard.vue";

export default {
  name: 'HomeView',
  components: {InputForm, ServicesCard, CertificateCard, MainExportPage},
  data() {
    return {
      showRepairDialog: false,
      currentRoomTour: 0,
      fullscreenDialog: false,
      valid: false,
      showKP: false,
      REPAIR_TYPE_ID: 1,
      FURNITURE_TYPE_ID: 2,
      CERTIFICATE_TYPE_ID: 3
    }
  },
  created() {
    this.$store.dispatch("reference/load")
  },
  destroyed() {
    this.$store.dispatch('inputFormStore/clear')
  },
  computed: {
    referenceLoading() {
      return this.$store.getters["reference/getLoading"]
    },
    user() {
      return this.$store.state.authentication.user;
    },
    services() {
      return this.$store.getters['reference/getService']
    },
    repairBlockData() {
      return this.services.filter(el => el.service_type_id === this.REPAIR_TYPE_ID)
    },
    repairBlockTitle() {
      return this.repairBlockData[0]?.service_type_name
    },
    serviceCost() {
      return this.$store.getters['reference/getServiceCost']
    },
    roomCount() {
      return this.$store.getters['inputFormStore/getRoomCount']
    },
    furnitureBlockData() {
      return this.services.filter(el => el.service_type_id === this.FURNITURE_TYPE_ID)
    },
    furnitureBlockTitle() {
      return this.furnitureBlockData[0]?.service_type_name
    },
    certificateBlockData() {
      return this.services.filter(el => el.service_type_id === this.CERTIFICATE_TYPE_ID)
    },
    certificateBlockTitle() {
      return this.certificateBlockData[0]?.service_type_name
    },
    blockAddPayments() {
      return this.$store.getters["inputFormStore/getBlockAddPayments"] ?? []
    },
    blockAddPayment() {
      return this.blockAddPayments.reduce(function (acc, obj) {
        return acc + obj.payment;
      }, 0)
    },
    totalPayment() {
      return this.formatNumber(this.$store.getters["inputFormStore/getTotalPayment"] + (this.blockAddPayment ?? 0))
    },
    serviceDetails() {
      return this.$store.getters['reference/getServiceDetail']
    },
    currentServiceDetail() {
      return this.serviceDetails.find(el => el.service_id === this.currentRoomTour && (el.room_id ?? this.roomCount) === this.roomCount)
    },
    currentServiceDetails() {
      return this.currentServiceDetail?.details ?? ''
    },
    currentTour() {
      return this.currentServiceDetail?.tour
    },
    tourType() {
      if (!this.currentTour) return null
      return ('' + this.currentTour).startsWith('http') ? 'iframe' : 'img'
    },
    inputData() {
      return this.$store.getters['inputFormStore/getInputData']
    },
    fullscreenDialogIcon() {
      return this.fullscreenDialog ? 'mdi-window-restore' : 'mdi-window-maximize'
    }
  },
  methods: {
    detailHandler(serviceId) {
      this.showRepairDialog = true
      this.currentRoomTour = serviceId
    },
    formatNumber(num) {
      return new Intl.NumberFormat("ru", {style: "decimal"}).format(num)
    },
    getImage(src) {
      return require(`@/assets/${src}`)
    },
    save() {
      post(API_PATH.setCommercialOffer, this.inputData).then(res => {
        const params = this.blockAddPayments.filter(el => el.id).map(el => ({
          commercial_offer_id: res.commercial_offer_id
          , service_id: el.id
          , payment: el.payment
        }))
        post(API_PATH.setCommercialOfferService, params).then(() => {

        })
        post(API_PATH.getCommercialOfferCrossSales, this.getCrossSalesParam()).then(res => {
          this.$store.dispatch("inputFormStore/setCrossSales", res)
          this.showKP = true
        })

      })
    },
    getCrossSalesParam() {
      return {
        repair_service_id: this.blockAddPayments.find(el => el.blockId === this.REPAIR_TYPE_ID)?.id ?? -1
        , furniture_service_id: this.blockAddPayments.find(el => el.blockId === this.FURNITURE_TYPE_ID)?.id ?? -1
      }
    }
  }
}
</script>
<style scoped lang="scss">
.viewer {
  display: grid;
  grid-template-columns: auto 460px;
  grid-template-rows: 1fr;
  height: 60vh;
  overflow: hidden;
  padding: 12px;

  &.fullscreen {
    height: 90vh;

    .scrollable {
      height: 84%;
    }
  }

  .column1 {
    display: flex;
    justify-content: center;
    align-items: center;

    img.card-description-image {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
      overflow: hidden;
    }
  }

  .column2 {
    flex-basis: 460px;
    max-width: 460px;
    overflow: hidden;
    padding: 0 12px;
  }

  .full-height {
    height: 100%;
    width: auto;
  }

  .scrollable {
    padding: 0 12px 12px 12px;
    height: 78%;
    overflow-y: auto;
    scrollbar-color: #FFFFFF #1e1e1e;

    .viewer-info-wrapper {
    }
  }
}

h1 {
  width: 100%;
  text-align: center;
}

.loading-reference {
  height: 100%;

  .loading-reference-circular {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
}

.home-page {
  background-image: url('@/assets/svg/black.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

</style>

<style lang="scss">
.text--white {
  color: white;
}

.page-container {
  max-width: 1160px;
}

.card-description-dialog {
  border-radius: var(--border-radius) !important;
}

.cert-list {
  padding-left: 0 !important;
}

.detail-list {
  list-style: none;
  padding-left: 0 !important;

  li {
    display: flex;
    padding: 4px 8px;

    &:hover {
      background-color: #111111;
      cursor: default;
    }

    div:first-child {
      flex-grow: 1;
    }
  }
}

</style>