<template>
  <div class="bloc-design-block">
    <div>
      <img src="/img/bloc/crossSales/15_1.png" width="100%" alt="">
    </div>
    <div>
      <div>
        <img src="/img/bloc/crossSales/15_2.png" width="100%" alt="">
      </div>
      <div class="bloc-design-block-label">
        <div>
          <img src="/img/bloc/logo/prostoDesign.png" height="100%" alt="">
        </div>
        <PaymentChip
            :payment="payment"
            large
            :active="active"
        />
      </div>
      <div>
        <div class="bloc-design-block-title">СЕРТИФИКАТ НА ИНДИВИДУАЛЬНЫЙ
          ДИЗАЙН-ПРОЕКТ ВОПЛОТИТ В ЖИЗНЬ
          ВСЕ САМЫЕ СМЕЛЫЕ РЕШЕНИЯ
        </div>
        <div class="bloc-design-block-subtitle">Создаём пространство, в котором хочется жить</div>
        <div class="bloc-design-block-info">
          <div>
            <ul>
              <li>Обмерный план с привязками</li>
              <li>План демонтажа перегородок</li>
              <li>План монтажа перегородок</li>
              <li>План расстановки мебели 2D</li>
              <li>План расстановки мебели 3D в сером цвете</li>
              <li>План розеток и электрики</li>
              <li>План освещения</li>
              <li>План освещения с привязками</li>
            </ul>
          </div>
          <div>
            <ul>
              <li>План теплых полов</li>
              <li>План водоснабжения и канализации</li>
              <li>Концептуальный дизайн будущего интерьера</li>
              <li>План пола с напольными покрытиями</li>
              <li>Визуализация помещений</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentChip from "@/components/exportBlocks/PaymentChip.vue";

export default {
  name: "BlocDesignBlock",
  components: {PaymentChip},
  props: {
    active: Boolean,
    payment: [String, Number]
  },
}
</script>

<style lang="scss">
.bloc-design-block {
  display: grid;
  grid-template-columns: 220px auto;
  gap: 20px;
  padding: 0 20px;


  .bloc-design-block-label {
    display: flex;
    height: 26px;

    div:first-child {
      flex-grow: 1;
    }
  }

  .bloc-design-block-title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    margin-top: 16px;
  }

  .bloc-design-block-subtitle {
    border-bottom: 1px solid #1e1e1e;
  }

  .bloc-design-block-info {
    display: grid;
    grid-template-columns: 1fr 1fr;

    ul {
      font-size: 8px;
      line-height: 12px;
      padding: 12px;

    }
  }
}
</style>