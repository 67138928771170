<template>
  <div
      :id="`ImageBlock_${id}`"
      class="d-flex child-flex image-block"
      :class="{'enabled-click': !!extPrice && !isPresent, active: activeId === item.id}">
    <template>
      <v-img
          :src="getImage(item.picture)"
          height="300"
          width="368"
          class="black service-img"
          @click="clickHandler(item)"

      >
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </template>

    <div class="service-info">
      <div class="service-info-title" :class="{'price': extPrice, 'present': isPresent}">
        <v-chip v-if="isPresent" color="success" class="px-5">
          <v-icon color="white">mdi-gift</v-icon>
        </v-chip>
        <v-chip v-if="extPrice && !isPresent" color="success" class="price-chip">+{{ formatPrice }} руб.</v-chip>
        <h2>{{ title }}</h2>
      </div>
      <template>
        <v-chip v-if="withService">
          <v-checkbox label="С сервисом" v-model="service" @change="$emit('change-service', { id: item.id, value: service})">
          </v-checkbox>
        </v-chip>
        <v-btn v-if="roomCount && serviceIdsDetail.includes(item.id)" color="secondary" x-small class="detail-btn"
               @click="$emit('detail')">
          Подробнее
        </v-btn>
      </template>
      <template v-if="id === CERTIFICATE_BLOC_ID">
        <v-text-field
            class="price-input"
            label="сумма в рублях"
            type="number"
            v-model.number="savedPrice"
            hide-spin-buttons
            clearable
            :solo="activeId === item.id"
            rounded
        ></v-text-field>
      </template>
    </div>
    <div class="addition-present" v-if="additionPresent">
      <v-icon color="white" class="addition-present-icon">
        {{ id !== CERTIFICATE_BLOC_ID ? 'mdi-gift' : 'mdi-information-variant' }}
      </v-icon>
      <div class="addition-present-type">
        {{ presentType }}
      </div>
      <div v-if="presentAmounts" class="addition-present-amounts">{{ presentAmounts }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageBlock",
  props: {
    id: {
      type: Number,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    activeId: {
      type: Number,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    isPresent: {
      type: Boolean,
      default: false
    },
    additionPresent: {
      type: String
    },
    withService:{
      type: Boolean,
      default: false
    }
  },
  emits: ['change-price', 'click-image', 'detail', 'change-service'],
  data() {
    return {
      showPriceDialog: false,
      savedItem: null,
      savedPrice: null,
      service: false,
      CERTIFICATE_BLOC_ID: 16
    }
  },
  watch: {
    savedPrice(value) {
      this.$emit('change-price', {value, id: this.id})
    }
  },
  computed: {
    title() {
      return this.name
    },
    serviceDetails() {
      return this.$store.getters['reference/getServiceDetail'] ?? []
    },
    serviceIdsDetail() {
      return this.serviceDetails.map(el => el.service_id) ?? []
    },
    extPrice() {
      return this.$store.getters['inputFormStore/getBlockPaymentById'](this.id)
    },
    formatPrice() {
      return this.extPrice ? new Intl.NumberFormat("ru", {style: "decimal"}).format(this.extPrice) : null
    },
    presentType() {
      return (this.additionPresent ?? '').split(' на ')[0]
    },
    presentAmounts() {
      return (this.additionPresent ?? '').split(' на ')[1] ?? ''
    },
    roomCount() {
      return this.$store.getters['inputFormStore/getRoomCount']
    }
  },
  methods: {
    clickHandler(item) {
      if (this.isPresent) return
      this.toggleImage(item)
    },
    toggleImage(item) {
      if (this.activeId === item.id) {
        this.$emit('click-image', {id: null, price: null})
      } else {
        this.$emit('click-image', {id: item.id, price: this.extPrice})
      }
    },
    addPrice() {
      this.toggleImage(this.savedItem)
      this.showPriceDialog = false
    },
    getImage(src) {
      if(src && src.indexOf('http') >= 0) {
        return src
      }
      return require(`@/assets/${src}`)
    }
  }
}
</script>

<style scoped lang="scss">


.image-block {
  position: relative;
  border-radius: var(--border-radius);

  &.enabled-click {
    cursor: pointer;
  }

  .price-chip {
    font-weight: 600;
    font-size: 12px;
  }

  .addition-present {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: black;
    color: #FFFFFF;
    width: 100%;
    box-sizing: border-box;
    padding: 8px;
    display: grid;
    grid-template-columns: 48px auto;
    column-gap: 8px;
    align-items: center;
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    font-weight: bold;
    font-size: clamp(0.5rem, 0.425rem + 0.375vw, 0.875rem);

    .addition-present-icon {
      grid-column: 1;
      grid-row: 1/3;
    }

    .addition-present-amounts:before {
      content: 'на ';
    }
  }

  .service-info {
    position: absolute;
    right: 0;
    top: 24px;
    display: flex;
    flex-direction: column;
    align-items: end;
    row-gap: 8px;

    .detail-btn {
      padding: 4px 8px;
      border-radius: 24px;
      margin-right: 4px;
    }

    .service-info-title {
      background-color: black;
      display: flex;
      align-items: center;
      border-radius: 24px 0 0 24px;

      font-size: 8px !important;
      color: white;

      h2 {
        height: 32px;
        display: flex;
        align-items: center;
        padding: 0 8px 0 12px;
      }

      &.price, &.present {

        h2 {
          padding: 0 8px;
        }
      }
    }
  }

  .service-img {
    border-radius: var(--border-radius);
    box-sizing: border-box;

    &.left-image {
      border-radius: var(--border-radius) 0 0 var(--border-radius);
    }

    &.right-image {
      border-radius: 0 var(--border-radius) var(--border-radius) 0;
    }
  }
}

.image-block.active .service-info-title {
  background-color: #2E7D32;
  color: black;
}
</style>
<style>

.image-block:not(.active) .v-image__image {
  opacity: 0.3;
  transition: transform 300ms ease-out, opacity 300ms ease-out;
}

.image-block .service-img:hover .v-image__image {
  opacity: 1;
  transition: transform 300ms ease-in, opacity 300ms ease-in;
}

.image-block.active .v-image__image {
  opacity: 1;
  transform: scale(1.2);
  transition: transform 300ms ease-in, opacity 300ms ease-in;
}

.price-input.v-text-field .v-input__slot{
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
</style>