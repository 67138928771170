<template>
  <v-card class="card__border-round">
    <v-card-text>
      <v-form v-model="valid" ref="inputForm">
        <div class="input-wrapper">
          <div class="input-field">
            <v-row>
              <v-col
                  cols="12"
                  sm="4"
              >
                <v-text-field
                    :label="`${!inputData.isPreApprovedAmount ? '* ' : ''}Предодобренная сумма`"
                    v-model.number="inputData.preApprovedAmount"
                    :rules="requirePreApprovedAmountRules"
                    :disabled="inputData.isPreApprovedAmount"
                    type="number"
                    hide-spin-buttons
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="3"
              >
                <v-checkbox v-model="inputData.isPreApprovedAmount" label="Не одобрена"
                            @change="changeIsPreApprovedAmount"></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-autocomplete
                    label="* ЖК"
                    :items="housingComplexes"
                    item-value="id"
                    item-text="name"
                    v-model="inputData.housingComplex"
                    :readonly="housingComplexes.length===1"
                ></v-autocomplete>
              </v-col>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-autocomplete
                    label="* Литер"
                    :items="housingLiters"
                    item-value="id"
                    item-text="name"
                    v-model="inputData.housingLiter"
                    @change="changeHousingLiter"
                    :disabled="!inputData.housingComplex"
                ></v-autocomplete>
              </v-col>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-autocomplete
                    label="* Планировка"
                    :items="filteredHousingPlans"
                    item-value="id"
                    item-text="name"
                    v-model="inputData.housingPlan"
                    @change="changeHousingPlan"
                    :disabled="!inputData.housingLiter"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-autocomplete
                    label="* Кол-во комнат"
                    :items="roomsCount"
                    item-value="id"
                    item-text="name"
                    v-model="inputData.roomCount"
                    :disabled="!inputData.housingLiter"
                ></v-autocomplete>
              </v-col>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    v-model.number="inputData.square"
                    :rules="requireRules"
                    type="number"
                    required
                    clearable
                    hide-spin-buttons
                >
                  <template #label>
                    <label>* Площадь м<sup>2</sup></label>
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    v-model.number="inputData.price"
                    :rules="requireRules"
                    required
                    clearable
                    type="number"
                    hide-spin-buttons
                >
                  <template #label>
                    <label>* Стоимость за м<sup>2</sup></label>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  md="2"
              >
                <v-text-field
                    label="* Ставка, %"
                    v-model.number="inputData.bet"
                    :rules="requireRules"
                    type="number"
                    required
                    clearable
                    hide-spin-buttons
                >
                </v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="2"
              >
                <v-text-field
                    label="* Срок, в годах"
                    v-model.number="inputData.numberOfPayments"
                    :rules="requireNumberOfPaymentsRules"
                    required
                    clearable
                    type="number"
                    hide-spin-buttons
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    label="Первоночальный взнос, руб."
                    v-model.number="inputData.deposit"
                    type="number"
                    clearable
                    :rules="depositRule"
                    hide-spin-buttons
                    @click:clear="depositClearClick"
                >
                </v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="4"
                  class="d-flex"
              >
                <v-radio-group v-model="inputData.depositPerc" row>
                  <v-radio
                      :label="customPerc"
                      hide-details
                      class="mr-3 percent-input"
                      :value="0"
                      @click="inputData.deposit=null"
                  ></v-radio>
                  <v-radio
                      v-for="(item, index) in depositList"
                      :key="index"
                      @click="setDepositPerc(item)"
                      :label="item.title"
                      hide-details
                      class="mr-3 percent-input"
                      :value="item.id"
                  >
                  </v-radio>
                </v-radio-group>
              </v-col>

            </v-row>
          </div>
          <div class="housing-plan-image">
            <v-img
                v-if="housingPlanImage"
                :src="housingPlanImage"
                max-height="350"
                contain
            ></v-img>
          </div>
          <div class="additional-services">
            <v-row>
              <v-col
                  cols="12"
                  md="3"
              >
                <div class="d-flex flex-column">
                  <v-btn-toggle
                      v-model="inputData.addSquare"
                      tile
                      color="primary"
                      group
                      @change="changeAddSquare"
                  >
                    <v-btn :value="1" class="ma-0" :disabled="inputData.presentId===1">
                      Кладовка
                    </v-btn>

                    <v-btn :value="2" class="ma-0" :disabled="inputData.presentId===2">
                      Парковка
                    </v-btn>
                  </v-btn-toggle>
                  <v-text-field
                      label="Цена"
                      v-model.number="inputData.addSquarePrice"
                      clearable
                      :disabled="!inputData.addSquare"
                      type="number"
                      hide-spin-buttons
                  >
                  </v-text-field>
                </div>
              </v-col>
              <v-col
                  cols="12"
                  md="4"
                  lg="9"
                  xl="9"
                  class="align-self-end"
              >
                <v-autocomplete
                    label="Подарок"
                    v-model="inputData.presentId"
                    :items="presents"
                    item-value="id"
                    item-text="name"
                    clearable
                ></v-autocomplete>
              </v-col>
            </v-row>
          </div>
        </div>
        <hr class="mb-3"/>
        <v-row>
          <v-col
              cols="12"
              md="4"
              lg="3"
          >
            <v-text-field
                label="Стоимость жилья, руб."
                :value="formattedCoast"
                readonly
            >
            </v-text-field>
          </v-col>
          <v-col
              cols="12"
              md="6"
              offset-lg="3"
              class="d-flex align-center"
          >
            <h2 style="color: black" class="mr-3">ЕЖЕМЕСЯЧНЫЙ ПЛАТЕЖ: <span>{{ formattedPayment }}</span></h2>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {pmt} from "@/resource/pmt";
import {VMoney} from 'v-money'

export default {
  name: "InputForm",
  data() {
    return {
      valid: false,
      money: {
        decimal: '.',
        thousands: ' ',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: true
      },
      inputData: {
        preApprovedAmount: null,
        isPreApprovedAmount: false,
        housingComplex: 1,
        housingLiter: null,
        housingPlan: null,
        price: null,
        square: null,
        roomCount: null,
        bet: null,
        deposit: null,
        depositPerc: 0,
        withDeposit: false,
        payment: null,
        numberOfPayments: null,
        addSquare: null,
        addSquarePrice: null,
        presentId: null
      },
      requireRules: [
        v => !!v || 'Поле обязательное к заполнению'
      ],
      requireNumberOfPaymentsRules: [
        v => !!v > 0 || 'Поле обязательное к заполнению',
        v => v > 0 && v <= 30 || 'Значение должно быть в диапазоне 1-30'
      ],
      requirePreApprovedAmountRules: [
        v => this.inputData.isPreApprovedAmount || (!!v && !this.inputData.isPreApprovedAmount) || 'Поле обязательное к заполнению'
      ],
      depositRule: [v => !!v && v <= this.fullCoast || !v || 'Внос превышает стоимость'],
      depositList: [
        {id: 20, title: '20%'}, {id: 30, title: '30%'}, {id: 40, title: '40%'}],
      disabledInputCursor: {cursor: 'default'}
    }
  },
  computed: {
    housingComplexes() {
      return this.$store.getters["reference/getHousingComplexes"]
    },
    housingLiters() {
      return this.$store.getters["reference/getHousingLiters"].filter(el => el.housing_complex_id === this.inputData.housingComplex)
    },
    housingPlans() {
      return this.$store.getters["reference/getHousingLiterPlans"]
    },
    filteredHousingPlans() {
      return this.housingPlans.filter(el => el.housing_liter_id === this.inputData.housingLiter)
    },
    roomsCount() {
      return this.$store.getters["reference/getHousingLiterRooms"].filter(el => el.housing_liter_id === this.inputData.housingLiter)
    },
    coast() {
      return this.inputData.price * this.inputData.square
    },
    fullCoast() {
      return this.$store.getters["inputFormStore/getFullCoast"]
    },
    formattedCoast() {
      return (Number.isFinite(this.coast) ? this.formatNumber(this.coast) : 0)
    },
    deposit() {
      if (this.inputData.depositPerc !== null && this.inputData.depositPerc > 0) {
        return Math.ceil(this.fullCoast * (this.inputData.depositPerc / 100))
      }
      return this.inputData.deposit
    },
    payment() {
      var ratePerPeriod = this.inputData.bet / 12 * 0.01,              // Процентная ставка по ссуде
          numberOfPayments = this.inputData.numberOfPayments * 12,   // Срок, мес.
          presentValue = this.fullCoast - (this.deposit ?? 0);                             // Стоимость
      var value = -pmt(ratePerPeriod, numberOfPayments, presentValue, null, null);
      return value ? +value.toFixed(2) : null
    },
    formattedPayment() {
      return (Number.isFinite(this.payment) ? this.formatNumber(this.payment) + ' руб./мес.' : null)
    },
    presents() {
      return this.$store.getters['reference/getPresents']
    },
    customPerc() {
      if (!this.inputData.depositPerc && this.deposit && this.fullCoast) {
        return `${Math.ceil(this.deposit / this.fullCoast * 100)}  %`
      }
      return '0 %'
    },
    housingPlanImage() {
      if (!this.inputData.housingPlan) {
        return null
      }
      return this.housingPlans.find(el => el.id === this.inputData.housingPlan).picture
    }
  },
  directives: {money: VMoney},
  watch: {
    inputData: {
      handler(value) {
        if (this.payment) {
          this.$store.dispatch('inputFormStore/setTotalPayment', this.payment)
        }
        this.$store.dispatch('inputFormStore/setInputData', value)
      }, deep: true
    },
    valid(value) {
      this.$emit('validate', value)
    },
    payment(value) {
      this.inputData.payment = value
    },
    fullCoast() {
      this.$refs['inputForm'].validate()
    }
  },
  methods: {
    formatNumber(num) {
      return new Intl.NumberFormat("ru", {style: "decimal"}).format(num)
    },
    setDepositPerc(item) {
      this.inputData.depositPerc = item.id
      this.inputData.deposit = this.deposit
    },
    changeIsPreApprovedAmount() {
      if (this.inputData.isPreApprovedAmount) {
        this.inputData.preApprovedAmount = null
      }
      this.$refs['inputForm'].validate()
      this.$refs['inputForm'].resetValidation()
    },
    changeAddSquare() {
      if (!this.inputData.addSquare) {
        this.inputData.addSquarePrice = null
      }
    },
    changeHousingLiter() {
      this.inputData.housingPlan = null
    },
    changeHousingPlan(housingLiterPlanId) {
      const plan = this.filteredHousingPlans.find(el => el.id === housingLiterPlanId)
      if (plan) {
        this.inputData.square = plan.square
        this.inputData.roomCount = plan.room_id
      }
    },
    depositClearClick() {
      this.inputData.deposit = null
      this.inputData.depositPerc = 0
    }
  }
}
</script>

<style scoped lang="scss">
.card__border-round {
  border-radius: var(--border-radius);
}

.input-wrapper {
  display: grid;
  grid-template-columns: auto 300px;
  grid-template-rows: 352px auto;
  gap: 12px;

  .input-field {
    flex-grow: 1;
    grid-column: 1;
    grid-row: 1;

    .percent-input {
      width: 75px;
    }
  }

  .additional-services {
    grid-column: 1/3;
    grid-row: 2;
  }

  .housing-plan-image {
    height: 352px;
  }
}

@media only screen and (width < 960px) {
  .input-wrapper {
    display: block;
  }
}
</style>