/**
 * Calculates the payment for a loan based on constant payments
 * and a constant interest rate.
 *
 * @param ratePerPeriod    - The interest rate for the loan.
 * @param numberOfPayments - The total number of payments for the loan.
 * @param presentValue     - The present value, or the total amount
 *                           that a series of future payments is worth now;
 *                           also known as the principal.
 * @param futureValue      - The future value, or a cash balance you want
 *                           to attain after the last payment is made.
 *                           If fv is omitted, it is assumed to be 0 (zero), that is,
 *                           the future value of a loan is 0.
 * @param type             - The number 0 (zero) or 1 and indicates when
 *                           payments are due.
 *
 * @returns {number}
 */
export function pmt(ratePerPeriod, numberOfPayments, presentValue, futureValue, type) {
  futureValue = typeof futureValue !== 'undefined' ? futureValue : 0;
  type = typeof type !== 'undefined' ? type : 0;
  if (ratePerPeriod != 0.0) {
    var q = Math.pow(1 + ratePerPeriod, numberOfPayments);
    return -(ratePerPeriod * (futureValue + (q * presentValue))) / ((-1 + q) * (1 + ratePerPeriod * (type)));
  } else if (numberOfPayments != 0.0) {
    return -(futureValue + presentValue) / numberOfPayments;
  }
  return 0;
}