<template>
  <v-card class="certificate-card">
    <v-card-title class="certificate-card-title">{{ title }}</v-card-title>
    <v-card-text>
      <div class="certificate-card-wrapper">
        <ImageBlock
            v-for="block of blockData" :key="block.id"
            class="certificate-card-item"
            :name="block.name"
            :price="setAddPayment(block.id)"
            :item="block"
            :active-id="active"
            :id="block.id"
            :is-present="block.present_id===presentId"
            :addition-present="block.addition_present_name"
            @detail="$emit('detail', block.id)"
            @click-image="clickImageHandler({...$event, blockId: id})"
            @change-price="changePrice(id, $event)"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import ImageBlock from "@/components/ImageBlock.vue";
import {pmt} from "@/resource/pmt";

export default {
  name: "CertificateCard",
  components: {
    ImageBlock,
  },
  props: {
    id: Number,
    title: String,
    blockData: Array,
    coastData: Array
  },
  data() {
    return {
      active: null,
      paymentStart: null,
      currentCoast: {},
    }
  },
  computed: {
    inputData() {
      return this.$store.getters["inputFormStore/getInputData"]
    },
    countRoom() {
      return this.inputData.roomCount
    },
    housingLiter() {
      return this.inputData.housingLiter
    },
    square() {
      return this.inputData?.square ?? null
    },
    coast() {
      return this.$store.getters["inputFormStore/getFullCoast"]
    },
    deposit() {
      return this.inputData?.deposit ?? 0
    },
    withDepositPerc() {
      return !!this.inputData?.depositPerc
    },
    depositPerc() {
      return !this.withDepositPerc ? Math.ceil(this.deposit / this.coast * 100) : this.inputData?.depositPerc
    },
    ratePerPeriod() {
      return this.inputData?.bet / 12 * 0.01
    },
    numberOfPayments() {
      return this.inputData?.numberOfPayments * 12
    },
    presentId() {
      return this.inputData?.presentId
    },
    fullCoast() {
      return this.$store.getters["inputFormStore/getFullCoast"]
    },
    payment() {
      var presentValue = this.fullCoast - this.deposit;                             // Стоимость
      var value = -pmt(this.ratePerPeriod, this.numberOfPayments, presentValue, null, null);
      return Number.isFinite(value) ? +value.toFixed(2) : null
    }
  },
  methods: {
    clickImageHandler({id, blockId, price}) {
      this.active = id
      this.$store.dispatch('inputFormStore/addBlockAddPayment', {id, blockId, payment: price})
    },
    addPrice(id) {
      if (!this.housingLiter || !this.countRoom) return
      const currentCoast = this.coastData.find(el => el.service_id === id
          && el.housing_liter_id === this.housingLiter
          && el.room_id === this.countRoom
      )
      if (!currentCoast) return null
      var serviceCoast = this.coast + (currentCoast.is_metr ? this.square * currentCoast.price : currentCoast.price)
      // Если указан платеж руками, то его вычитаю из общей стоимости, иначе вычитаю процент
      var presentValue = !this.depositPerc ? serviceCoast : serviceCoast - serviceCoast * this.depositPerc / 100;
      this.currentCoast[id] = (currentCoast.is_metr ? this.square * currentCoast.price : currentCoast.price)
      if (!this.currentCoast[id]) return null;
      var value = -pmt(this.ratePerPeriod, this.numberOfPayments, presentValue, null, null);
      return Number.isFinite(value) ? +(value - this.payment).toFixed(2) : null
    },
    setAddPayment(id) {
      const value = this.addPrice(id)
      this.$store.dispatch("inputFormStore/addBlockPayment", {id, value: value})
      return value
    },
    changePrice(blockId, item) {
      this.$store.dispatch("reference/updateServiceCoast", {id: item.id, value: item.value ? +item.value : 0})
      if (item.id === this.active) {
        this.$store.dispatch('inputFormStore/addBlockAddPayment', {
          id: item.id,
          blockId: blockId,
          payment: item.value ? this.addPrice(item.id) : null
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.certificate-card {

  .certificate-card-title {
    font-size: 2em;
    width: 100%;
    display: block;
    text-align: center;
  }

  .certificate-card-wrapper {
    display: flex;
    column-gap: 12px;

    .certificate-card-item {
      width: calc((100% - 24px) / 3);

      &:last-child {
        flex-grow: 1;
        max-height: 300px;
      }

      &:nth-child(3n) {
        padding: 0;
      }

      &:last-child {
        padding: 0;
      }
    }
  }
}
</style>