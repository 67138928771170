import {API_PATH} from "@/_services/API_PATH";
import {get} from "@/_services/api";

export const adminReference = {
    namespaced: true,
    state: {
        loading: false,
        rooms: [],
        roomTours: [],
        materials: [],
        users: [],
        companies: [],
        serviceCosts: [],
        housingComplex: [],
        housingLiter: [],
        companyService: []
    },
    getters: {
        getLoading: (state) => state.loading,
        getRooms: (state) => state.rooms,
        getRoomTours: (state) => state.roomTours,
        getMaterials: (state) => state.materials,
        getUsers: (state) => state.users,
        getCompanies: (state) => state.companies,
        getServiceCosts: (state) => state.serviceCosts,
        getHousingComplex: (state) => state.housingComplex,
        getHousingLiter: (state) => state.housingLiter,
        getCompanyService: (state) => state.companyService,
    },
    mutations: {
        SET(state, payload) {
            state[payload.name] = payload.value;
        },
        SET_ROOMS(state, payload) {
            state.rooms = payload
        },
        SET_ROOM_TOURS(state, payload) {
            state.roomTours = payload
        },
        SET_MATERIALS(state, payload) {
            state.materials = payload
        },
        SET_USERS(state, payload) {
            state.users = payload
        },
        ADD_USERS(state, payload) {
            state.users.push(payload)
        },
        SET_SERVICE_COST(state, payload) {
            for (const serviceCostData of payload) {
                const serviceCost = state.serviceCosts.find(item => item.id === serviceCostData.id)
                if (serviceCost) {
                    for (const key in serviceCost) {
                        if (Object.prototype.hasOwnProperty.call(serviceCostData, key)) {
                            serviceCost[key] = serviceCostData[key]
                        }
                    }
                } else {
                    state.serviceCosts.push(serviceCostData)
                }
            }
        },
        SET_SERVICE_COSTS(state, payload) {
            state.serviceCosts = payload
        },
        SET_COMPANIES(state, payload) {
            state.companies = payload
        },
        SET_LOADING(state, value) {
            state.loading = value
        }
    },
    actions: {
        load({commit}) {
            commit('SET_LOADING', true)
            Promise.all([
                get(API_PATH.getUsers),
                get(API_PATH.company),
                get(API_PATH.serviceCosts),
                get(API_PATH.housingLiter),
                get(API_PATH.housingComplex),
                get(API_PATH.getRooms),
                get(API_PATH.companyService),
            ]).then(res => {
                commit('SET', {name: 'users', value: res[0]})
                commit('SET', {name: 'companies', value: res[1]})
                commit('SET', {name: 'serviceCosts', value: res[2]})
                commit('SET', {name: 'housingLiter', value: res[3]})
                commit('SET', {name: 'housingComplex', value: res[4]})
                commit('SET', {name: 'rooms', value: res[5]})
                commit('SET', {name: 'companyService', value: res[6]})

            }).finally(() => {
                commit('SET_LOADING', false)
            })
        },
        setUser({commit, state}, payload) {
            const users = state.users.filter(user => user.id !== payload.id)
            users.push(payload)
            commit('SET_USERS', users)
        },
        delUser({commit, state}, payload) {
            const users = state.users.filter(user => user.id !== payload.id)
            commit('SET_USERS', users)
        },
        setServiceCost({commit}, payload) {
            commit('SET_SERVICE_COST', payload)
        }
    }
}