import {userService} from '@/_services';
import {router} from "@/router";

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? {status: {loggedIn: true}, user}
    : {status: {}, user: null};

export const authentication = {
    namespaced: true,
    state: initialState,
    actions: {
        login({commit, dispatch}, payload) {
            commit('loginRequest', {username: payload.username});

            userService.login(payload)
                .then(
                    user => {
                        commit('loginSuccess', user);
                        console.log(user)
                        if (user.is_admin) {
                            router.push('admin')
                        } else {
                            router.push('/')
                        }
                    },
                    error => {
                        console.log('error', error);
                        dispatch('snackbars/setSnackbar', {
                            content: 'Не верное имя пользователя или пароль',
                            color: 'error',
                            timeout: 5000
                        }, {root: true})
                        commit('loginFailure', error);
                    }
                );
        },
        logout({commit}) {
            userService.logout();
            commit('logout');
        }
    },
    mutations: {
        loginRequest(state, user) {
            state.status = {loggingIn: false};
            state.user = user;
        },
        loginSuccess(state, user) {
            state.status = {loggedIn: true};
            state.user = user;
        },
        loginFailure(state) {
            state.status = {};
            state.user = null;
        },
        logout(state) {
            state.status = {};
            state.user = null;
        }
    },
    getters: {
        getUser: (state) => state.user,
        getLoggedIn: (state) => state.status.loggedIn
    }
}