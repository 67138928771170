<template>
  <v-footer color="black">
    <v-container class="page-footer">
      <div>
        <a href="https://bloc-group.ru">
          <v-img
              alt="BLOC Logo"
              class="shrink"
              contain
              width="150"
              :src="require('@/assets/bloc/LOGO_white.svg')"
          />
        </a>
      </div>
      <div class="help-text">
        <a href="tel:88007003394" class="phone-number">8-800-700-33-94</a>
        <div>Поддержка для менеджеров</div>
      </div>
      <div class="d-flex">
        <a href="https://t.me/blocsupport_client_bot"
           target="_blank"
           rel="nofollow noopener"
           aria-label="telegram">
          <v-img
              alt="telegram"
              class="shrink mr-3"
              contain
              :src="require('@/assets/svg/telegram.svg')"
          />
        </a>
        <a href="https://wa.me/message/BSIJWX3ZJTFYA1"
           target="_blank"
           rel="nofollow noopener"
           aria-label="whatsapp">
          <v-img
              alt="telegram"
              class="shrink mr-3"
              contain
              :src="require('@/assets/svg/whatsapp.svg')"
          />
        </a>
        <a href="mailto:pushkina@bloc-group.ru"
           target="_blank"
           rel="nofollow noopener"
           aria-label="Электронная почта">
          <v-img
              alt="Электронная почта"
              class="shrink"
              contain
              :src="require('@/assets/svg/mail.svg')"
          />
        </a>
      </div>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "TheAppFooter"
}
</script>

<style scoped lang="scss">

.page-footer {
  height: 86px;
  width: 100%;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  a {
    color: #ff8562;

    &:visited {
      color: #ff8562;
    }

    &:hover {
      color: #ffffff;
    }
  }

  .phone-number {
    font-size: 26px;
    color: #ffffff;
    text-decoration: none;
  }

  .help-text {
    text-align: center;
  }
}

@media (width < 600px) {
  .page-footer {
    height: 100%;
    flex-direction: column;
  }
}
</style>