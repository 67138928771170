<template>
  <div class="payment-chip" :class="{large, small, active}"><span>{{ formatPayment }}</span></div>
</template>

<script>
export default {
  name: "PaymentChip",
  props: {
    active: Boolean,
    large: Boolean,
    small: Boolean,
    payment: [Number, String]
  },
  computed: {
    formatPayment() {
      return this.payment ? `+ ${this.formattedValue(this.payment)} ₽/мес` : ''
    }
  },
  methods: {
    formatNumber(num) {
      return new Intl.NumberFormat("ru", {style: "decimal"}).format(num)
    },
    formattedValue(value) {
      return (Number.isFinite(value) ? this.formatNumber(value) : 0)
    },
  }
}
</script>

<style lang="scss">
.payment-chip {
  height: 20px;
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 700;
  text-transform: none;

  span {
    height: 100%;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-radius: 4px;
  }

  &.large {
    height: 26px;
    font-size: 12px;
  }

  &.small{
    height: 12px;
    font-size: 6px;

    span{
      height: 16px;
      padding: 0 4px;
    }
  }

  &.active {
    span {
      background-color: white;
      color: black;
    }
  }
}
</style>