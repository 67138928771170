<template>
  <div class="block-partner">
    <div>
      <img :src="src" height="100%" alt="">
    </div>
    <div>
      {{ description}}
    </div>
  </div>
</template>

<script>
export default {
  name: "BlocPartnerBlock",
  props:{
    src: String,
    description: String
  }
}
</script>

<style lang="scss">
.block-partner {
    display: grid;
    grid-template-rows: 24px auto;
    gap: 6px;
    border-radius: 8px;
    padding: 10px;
    background-color: black;
    color: white;

    font-size: 8px;
    line-height: 10px;
  }
</style>