import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
import {userService} from "@/_services";
import HomeView from "@/views/HomeView.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
    }, {
        path: '/admin',
        name: 'admin',
        component: () => import(/* webpackChunkName: "AdminView" */ '../views/AdminView.vue')
    }, {
        path: '/export',
        name: 'export',
        component: () => import(/* webpackChunkName: "MainExportPage" */ '../components/exportBlocks/MainExportPage.vue')
    },

    /* {path: '*', redirect: '/'}*/
]

export const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const jwt = localStorage.getItem('jwt');
    if (jwt) {
        userService.getMe().then(user => {
            store.commit('authentication/loginSuccess', user);
            if (to.path === '/login') {
                return next('/')
            }

        }).catch(() => {
            userService.logout()
        })
    } else {
        const loggedIn = store.state.authentication.status.loggedIn
        if (authRequired && !loggedIn) {
            return next('/login');
        }


    }
    next();
})

export default router
