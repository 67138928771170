<template>
  <div class="cross-sales-card" :class="{'two-column': twoColumn}">
    <div class="cross-sales-card-image">
      <img
          :src="imagePath"
          height="130px"
      />
    </div>
    <div class="cross-sales-card-title">{{ title }}</div>
    <div class="cross-sales-card-subtitle">{{ subtitle }}</div>
    <PaymentChip
        v-if="payment"
      :payment="payment"
    />
  </div>
</template>

<script>
import PaymentChip from "@/components/exportBlocks/PaymentChip.vue";

export default {
  name: "CrossSalesCard",
  components: {PaymentChip},
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    payment: {
      type: [Number, String]
    },
    imagePath: {
      type: String
    },
    twoColumn:{
      type: Boolean,
      default: false
    }
  },
  computed: {
    formatPayment() {
      return this.payment ? `+ ${this.payment} ₽/мес` : ''
    }
  }
}
</script>

<style scoped lang="scss">
.cross-sales-card {
  min-height: 192px;
  width: 124px;
  display: grid;
  grid-template-rows: 130px auto auto 20px;
  font-size: 10px;

  &.two-column{
    width: 269px;
  }

  .cross-sales-card-image {
    position: relative;

    img {
      width: 100%;
      height: 130px;
      border-radius: 6px;
    }
  }

  .cross-sales-card-title {
    font-size: 9px;
    font-weight: 700;
    line-height: 12px;
    margin: 8px 0 4px 0;
  }

  .cross-sales-card-subtitle {
    font-size: 6px;
    flex-grow: 1;
    line-height: 8px;
  }

}

</style>