<template>
  <div class="service-comfort-block" :class="{active}">
    <div class="service-comfort-block-column column-one">
      <img src="/img/bloc/crossSales/2_1.png" height="100%" width="100%" alt="start"/>
    </div>
    <div class="service-comfort-block-column column-two">
      <div>
        <img src="/img/bloc/crossSales/2_2.png" height="100%" width="100%" alt="start"/>
      </div>
      <div>
        <img src="/img/bloc/crossSales/2_3.png" height="100%" width="100%" alt="start"/>
      </div>
      <div>
        <img src="/img/bloc/crossSales/2_4.png" height="100%" width="100%" alt="start"/>
      </div>
      <div>
        <img src="/img/bloc/crossSales/2_5.png" height="100%" width="100%" alt="start"/>
      </div>
    </div>
    <div class="service-comfort-block-column column-three">
      <h1>BLOC COMFORT</h1>
      <p>
        Повышенный класс ремонта, выполненный из высококачественных материалов и с использованием технологий, идущих в
        ногу со временем. Доступен в светлом Comfort light и темном Comfort dark исполнении.
        <ul>
          <li>Ламинат by Quick Step 32-33 класс</li>
          <li>Плинтусы МДФ</li>
          <li>Скрытые трассы под сплит-систему</li>
          <li>Скрытые карнизы</li>
          <li>Плитка Kerama Marazzi</li>
          <li>Унитаз на инсталяции</li>
          <li>Зеркало с LED подсветкой</li>
        </ul>
      </p>
      <PaymentChip
          :payment="payment"
          :active="active"
          large
      />
    </div>
  </div>
</template>

<script>
import PaymentChip from "@/components/exportBlocks/PaymentChip.vue";

export default {
  name: "ServiceComfortBlock",
  components: {PaymentChip},
  props: {
    active: Boolean,
    payment: [Number, String]
  },
  data() {
    return {}
  }
}
</script>


<style lang="scss">
.service-comfort-block {
  width: 595px;
  height: 240px;
  position: relative;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  padding: 20px;
  gap: 10px;
  box-sizing: border-box;
  color: black;
  background-color: white;

  &.active {
    color: white;
    background-color: black;
  }

  .service-comfort-block-column {
    p {
      margin-bottom: 16px;
    }

    img {
      border-radius: 6px;
    }

    &.column-two {
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 134px 58px;
      gap: 10px;

    }

    &.column-three {
      h1 {
        font-size: 18px;
        margin-bottom: 12px;
      }

      p {
        font-size: 8px;
        line-height: 10px;

        ul {
          margin-top: 4px;
        }
      }
    }
  }

}


</style>