import {API_PATH} from "@/_services/API_PATH";
import {get} from "@/_services/api";
import Vue from "vue";

export const reference = {
    namespaced: true,
    state: {
        loading: false,
        housingComplexes: [],
        housingLiters: [],
        housingLiterPlans: [],
        housingLiterRooms: [],
        service: [],
        presents: [],
        serviceCost: [],
        serviceDetail: [],
    },
    getters: {
        getHousingComplexes: (state) => state.housingComplexes,
        getHousingLiters: (state) => state.housingLiters,
        getHousingLiterPlans: (state) => state.housingLiterPlans,
        getHousingLiterRooms: (state) => state.housingLiterRooms,
        getLoading: (state) => state.loading,
        getService: (state) => state.service,
        getServiceCost: (state) => state.serviceCost,
        getServiceDetail: (state) => state.serviceDetail,
        getPresents: (state) => state.presents,
    },
    mutations: {
        SET_HOUSING_COMPLEXES(state, payload) {
            state.housingComplexes = payload
        },
        SET_HOUSING_LITERS(state, payload) {
            state.housingLiters = payload
        },
        SET_HOUSING_LITER_PLANS(state, payload) {
            state.housingLiterPlans = payload
        },
        SET_HOUSING_LITER_ROOMS(state, payload) {
            state.housingLiterRooms = payload
        },
        SET_SERVICES(state, payload) {
            state.service = payload
        },
        SET_SERVICE_COST(state, payload) {
            state.serviceCost = payload
        },
        SET_SERVICE_DETAIL(state, payload) {
            state.serviceDetail = payload
        },
        SET_PRESENTS(state, payload) {
            state.presents = payload
        },
        SET_LOADING(state, value) {
            state.loading = value
        },
        UPDATE_SERVICE_COST(state, payload) {
            const coasts = state.serviceCost.filter(el => el.service_id === payload.id)
            for(const coast of coasts){
                Vue.set(coast, 'price', payload.value)
            }
        }
    },
    actions: {
        load({commit}) {
            commit('SET_LOADING', true)
            Promise.all([
                get(API_PATH.getHousingComplexes),
                get(API_PATH.getHousingLiters),
                get(API_PATH.getHousingLiterPlans),
                get(API_PATH.getHousingLiterRooms),
                get(API_PATH.getService),
                get(API_PATH.getServiceCost),
                get(API_PATH.getServiceDetail),
                get(API_PATH.getPresents),
            ]).then(res => {
                commit('SET_HOUSING_COMPLEXES', res[0])
                commit('SET_HOUSING_LITERS', res[1])
                commit('SET_HOUSING_LITER_PLANS', res[2])
                commit('SET_HOUSING_LITER_ROOMS', res[3])
                commit('SET_SERVICES', res[4]),
                commit('SET_SERVICE_COST', res[5]),
                commit('SET_SERVICE_DETAIL', res[6]),
                commit('SET_PRESENTS', res[7])
            }).finally(() => {
                commit('SET_LOADING', false)
            })
        },
        updateServiceCoast({commit}, payload) {
            commit('UPDATE_SERVICE_COST', payload)
        }
    }
}