export const API_PATH = {
    getHousingComplexes: '/house_complex/',
    getHousingLiters: '/housing_liter/',
    getHousingLiterPlans: '/housing_liter/plan/',
    getHousingLiterRooms: '/housing_liter/room/',
    getService: '/service/',
    getServiceCost: '/service/cost/',
    serviceCosts: '/service/costs/',
    getServiceDetail: '/service/detail/',
    getPresents: '/present/',
    getRooms: '/reference/room/',
    housingComplex: '/reference/housing_complex/',
    housingLiter: '/reference/housing_liter/',
    getRoomTours: '/reference/room_tour/',
    getMaterials: '/reference/material/',
    company: '/reference/company/',
    companyService: '/reference/company_service/',
    setCommercialOffer: '/commercial_offer/',
    setCommercialOfferService: '/commercial_offer/service/',
    getCommercialOfferCrossSales: '/commercial_offer/cross_sales/',
    getCommercialOffersStatistics: '/commercial_offer/statistic/',
    getUsers: '/users/',
    currentUser: '/users/me/'
}