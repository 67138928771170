import {get} from "@/_services/api";
import {API_PATH} from "@/_services/API_PATH";

export const userService = {
    login,
    logout,
    getMe
};

function login(payload) {
    var formBody = [];
    for (var property in payload) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(payload[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
        body: formBody
    };

    return fetch(`${process.env.VUE_APP_BASE_URL}/token`, requestOptions)
        .then(handleResponse)
        .then(jwt => {
            // login successful if there's a jwt token in the response
            if (jwt.access_token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('jwt', JSON.stringify(jwt));
            }

            return jwt;
        }).then(() => {
            return getMe()
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('jwt');
}

function getMe() {
    return get(API_PATH.currentUser).then(res => {
        return Promise.resolve(res);
    }).catch(response => {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            logout();
            location.reload(true);
        }
        return Promise.reject(response);
    })
}

// TODO: причесать нужно дальше
function handleResponse(response) {
    return response.json().then(data => {
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return Promise.resolve(data);
    });
}