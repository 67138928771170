<template>
  <v-app>
    <v-app-bar
        app
        color="black"
        flat
        height="86"
    >
      <v-container>
        <div class="d-flex">
          <div class="d-flex align-center justify-center flex-grow-1" style="position: relative">
            <img
                alt="BLOC Logo"
                height="28px"
                :src="require('@/assets/bloc/logo_bloc_white.png')"
            />
            <div v-if="loggedIn" class="vertical-line"></div>
            <img
                v-if="loggedIn && companyLogo"
                alt="Logo"
                height="28px"
                :src="companyLogo"
            />
            <h1 v-if="isAdmin" class="white--text">Администратор</h1>
          </div>
          <TheAccountButton
              v-if="loggedIn"
              class="account-button"
              :company-name="companyName"
              :username="username"
              :company-logo="companyLogo"
              @logout="logout"
          />
        </div>
      </v-container>
    </v-app-bar>
    <v-main>
      <router-view/>
      <TheSnackbar/>
    </v-main>
    <TheAppFooter/>
  </v-app>
</template>

<script>
import TheAppFooter from "@/components/TheAppFooter.vue";
import {version} from "/package.json"
import TheSnackbar from "@/components/ui/TheSnackbar.vue";

export default {
  components: {
    TheAppFooter,
    TheSnackbar,
    TheAccountButton: () => import("@/components/TheAccountButton.vue")
  },
  computed: {
    user() {
      return this.$store.getters["authentication/getUser"] ?? null
    },
    loggedIn() {
      return this.$store.getters["authentication/getLoggedIn"]
    },
    companyLogo() {
      return this.user?.company_logo ? `/img/${this.user.company_logo}` : null
    },
    isAdmin() {
      return this.user?.is_admin
    },
    username() {
      return this.user?.username ?? ''
    },
    companyName() {
      return this.user?.company_name ?? ''
    }
  },
  beforeCreate() {
    console.log(`App version:  ${version}`)
  },
  methods: {
    logout() {
      this.$store.dispatch('authentication/logout')
      this.$router.push('login')
    }
  }
}
</script>

<style lang="scss">
html, body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: 'TT Norms', Avenir, Helvetica, Arial, sans-serif;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #000000 #FFFFFF;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

*::-webkit-scrollbar-track {
  background-color: #FFFFFF;
}

*::-webkit-scrollbar-track:hover {
  background-color: #F4F4F4;
}

*::-webkit-scrollbar-track:active {
  background-color: #000000;
}

*::-webkit-scrollbar-thumb {
  background-color: #000000;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #747474;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #F4F4F4;
}

.vertical-line:after {
  content: "";
  width: 1px;
  border-left: 2px solid #ffffff;
  display: block;
  height: 38px;
  color: #ffffff;
  box-sizing: border-box;
  margin: 0 8px 0 12px;
}

:root {
  --border-radius: 16px;
}
</style>

<style scoped lang="scss">
.app-title {
  font-size: 1.5em;
  margin-left: 3em;
}
</style>
