<template>
  <v-card class="services-card">
    <v-card-title class="service-card-title">{{ title }}</v-card-title>
    <v-card-text>
      <div class="services-card-wrapper">
        <div class="services-card-carousel" :id="`carousel_${id}`">
          <swiper
              v-bind="swiperOptions"
              class="mySwiper"
          >
            <swiper-slide v-for="block of blockData" :key="block.id">
              <ImageBlock
                  class="services-card-item"
                  :class="{'certificate-block': id===CERTIFICATE_BLOC_ID}"
                  :name="block.name"
                  :item="block"
                  :active-id="active"
                  :id="block.id"
                  :is-present="block.present_id && block.present_id===presentId"
                  :addition-present="block.addition_present_name"
                  :with-service="withService"
                  @detail="$emit('detail', block.id)"
                  @click-image="clickImageHandler({...$event, blockId: id})"
                  @change-service="changeServiceHandler({...$event, blockId: id})"
              />
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {pmt} from "@/resource/pmt";
import ImageBlock from "@/components/ImageBlock.vue";
import {Swiper, SwiperCore, SwiperSlide} from "swiper-vue2";
import {Mousewheel, Navigation, Pagination} from 'swiper'
import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation, Mousewheel, Pagination])
export default {
  name: "ServicesCard",
  components: {
    ImageBlock,
    Swiper,
    SwiperSlide
  },
  props: {
    id: Number,
    title: String,
    blockData: Array,
    coastData: Array,
    withService: {
      type: Boolean,
      default: false
    }
  },
  emits: ['detail'],
  data() {
    return {
      active: null,
      paymentStart: null,
      currentCost: {},
      withServices: {},
      swiperOptions: {
        slidesPerGroup: this.$vuetify.breakpoint.xs ? 1 : this.$vuetify.breakpoint.sm ? 2 : 3,
        slidesPerView: this.$vuetify.breakpoint.xs ? 1 : this.$vuetify.breakpoint.sm ? 2 : 3,
        spaceBetween: 12,
        freeMode: true,
        scrollbar: false,
        mousewheel: false,
        navigation: true,
        pagination: false
      },
      CERTIFICATE_BLOC_ID: 3
    }
  },
  computed: {
    displayHelper() {
      return this.$vuetify.breakpoint.name
    },
    inputData() {
      return this.$store.getters["inputFormStore/getInputData"]
    },
    countRoom() {
      return this.inputData.roomCount
    },
    housingLiter() {
      return this.inputData.housingLiter
    },
    square() {
      return this.inputData?.square ?? null
    },
    coast() {
      return this.$store.getters["inputFormStore/getFullCoast"]
    },
    deposit() {
      return this.inputData?.deposit ?? 0
    },
    withDepositPerc() {
      return !!this.inputData?.depositPerc
    },
    depositPerc() {
      return !this.withDepositPerc ? Math.ceil(this.deposit / this.coast * 100) : this.inputData?.depositPerc
    },
    ratePerPeriod() {
      return this.inputData?.bet / 12 * 0.01
    },
    numberOfPayments() {
      return this.inputData?.numberOfPayments * 12
    },
    presentId() {
      return this.inputData?.presentId
    },
    fullCoast() {
      return this.$store.getters["inputFormStore/getFullCoast"]
    },
    payment() {
      var presentValue = this.fullCoast - this.deposit;                             // Стоимость
      var value = -pmt(this.ratePerPeriod, this.numberOfPayments, presentValue, null, null);
      return Number.isFinite(value) ? +value.toFixed(2) : null
    }
  },
  mounted() {
    for (const block of this.blockData) {
      this.setAddPayment(block.id)
    }
  },
  watch: {
    presentId(value) {
      if (value === this.active) {
        this.active = null
        this.currentCost = {}
        this.$store.dispatch('inputFormStore/addBlockAddPayment', {blockId: this.id, payment: null})
      }
    },
    displayHelper() {
      this.swiperOptions.slidesPerView = this.$vuetify.breakpoint.xs ? 1 : this.$vuetify.breakpoint.sm ? 2 : 3
      this.swiperOptions.slidesPerGroup = this.$vuetify.breakpoint.xs ? 1 : this.$vuetify.breakpoint.sm ? 2 : 3
    },
    inputData: {
      deep: true,
      handler() {
        for (const block of this.blockData) {
          this.setAddPayment(block.id)
        }
      }
    }
  },
  methods: {
    clickImageHandler({id, blockId, price}) {
      this.active = id
      this.$store.dispatch('inputFormStore/addBlockAddPayment', {id, blockId, payment: price})
    },
    addPrice(id) {
      if (!this.housingLiter || !this.countRoom) return
      const currentCost = this.coastData.find(el => el.service_id === id
          && el.housing_liter_id === this.housingLiter
          && el.room_id === this.countRoom
      )
      if (!currentCost) return null
      const price = !this.withServices[id] ? currentCost.price : currentCost.service_price
      var serviceCost = this.coast + (currentCost.is_metr ? this.square * price : price)
      // Если указан платеж руками, то его вычитаю из общей стоимости, иначе вычитаю процент
      var presentValue = !this.depositPerc ? serviceCost : serviceCost - serviceCost * this.depositPerc / 100;
      // this.currentCost[id] = (currentCost.is_metr ? this.square * currentCost.price : currentCost.price)
      // if (!this.currentCost[id]) return null;
      var value = -pmt(this.ratePerPeriod, this.numberOfPayments, presentValue, null, null);
      return Number.isFinite(value) ? +(value - this.payment).toFixed(2) : null
    },
    setAddPayment(id) {
      const value = this.addPrice(id)
      this.$store.dispatch("inputFormStore/addBlockPayment", {id, value: value})
      return value
    },
    changeServiceHandler({id, blockId, value}) {
      this.withServices[id] = value
      const payment = this.setAddPayment(id)
      if (this.active === id) {
        this.$store.dispatch('inputFormStore/addBlockAddPayment', {id, blockId, payment: payment})
      }
    }
  }
}
</script>

<style scoped lang="scss">
.services-card {
  border-radius: var(--border-radius);
}

.service-card-title {
  font-size: 2em;
  width: 100%;
  display: block;
  text-align: center;
}

.services-card-wrapper {
  width: 100%;

  .services-card-carousel {
    overflow: auto;
    scroll-behavior: smooth;
    scrollbar-width: none;
    width: 100%;

    &::-webkit-scrollbar {
      height: 0;
    }

    .services-card-content {
      display: flex;
      column-gap: 12px;

      .services-card-item2 {
        width: calc((100% - 24px) / 3);

        &.certificate-block:last-child {
          flex-grow: 1;
          max-height: 300px;
        }

        &:nth-child(3n) {
          padding: 0;
        }

        &:last-child {
          padding: 0;
        }
      }
    }
  }
}


#prev,
#next {
  display: flex;
  justify-content: center;
  align-content: center;
  background: white;
  border: none;
  padding: 8px;
  border-radius: 50%;
  outline: 0;
  cursor: pointer;
  position: absolute;
}

#prev {
  top: 55%;
  left: 0;
  transform: translate(50%, -50%);
  display: none;
}

#next {
  top: 55%;
  right: 0;
  transform: translate(-50%, -50%);
}

</style>

<style lang="scss">
.services-card-carousel {
  .swiper-button-next,
  .swiper-button-prev {
    background-color: white;
    border-radius: 50%;
    right: 10px;
    padding: 22px;
    color: #000 !important;
    fill: black !important;
    stroke: black !important;
    background-size: 32px;

    &:after {
      content: ""
    }

    &:hover {
      background-color: #eeeeee;
    }

    &.swiper-button-disabled {
      display: none;
    }
  }

  .swiper-button-next {
    background-image: url("@/assets/svg/chevron_next.svg") !important;
    background-position: 60% 50%;
  }

  .swiper-button-prev {
    background-image: url("@/assets/svg/chevron_prev.svg") !important;
    background-position: 40% 50%;
  }
}
</style>
