<template>
  <div class="service-start-block" :class="{active}">
    <div class="service-start-block-column column-one">
      <img src="/img/bloc/crossSales/1_1.png" height="100%" width="100%" alt="start"/>
    </div>
    <div class="service-start-block-column column-two">
      <div>
        <img src="/img/bloc/crossSales/1_2.png" height="100%" width="100%" alt="start"/>
      </div>
      <div>
        <img src="/img/bloc/crossSales/1_3.png" height="100%" width="100%" alt="start"/>
      </div>
      <div>
        <img src="/img/bloc/crossSales/1_4.png" height="100%" width="100%" alt="start"/>
      </div>
    </div>
    <div class="service-start-block-column column-three">
      <h1>BLOC START</h1>
      <p>
        Базовый ремонт, выполненный согласно стандартам и регламентам BLOC.
        <ul>
          <li>Ламинат 32 класс</li>
          <li>Плинтусы ПВХ 80мм</li>
          <li>Флизелиновые обои</li>
          <li>(на виниловой основе)</li>
          <li>Электрика Schneider</li>
          <li>Дизайнерская сантехника FRAP</li>
        </ul>
      </p>
      <PaymentChip
          :payment="payment"
          :active="active"
          large
      />
    </div>
  </div>
</template>

<script>
import PaymentChip from "@/components/exportBlocks/PaymentChip.vue";

export default {
  name: "ServiceStartBlock",
  components: {PaymentChip},
  props: {
    active: Boolean,
    payment: [Number, String]
  },
  data() {
    return {}
  }
}
</script>


<style lang="scss">
.service-start-block {
  width: 595px;
  height: 240px;
  position: relative;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  padding: 20px;
  gap: 10px;
  box-sizing: border-box;
  color: black;
  background-color: white;

  &.active {
    color: white;
    background-color: black;
  }

  .service-start-block-column {
    p{
      margin-bottom: 16px;
    }

    img {
      border-radius: 6px;
    }

    &.column-two {
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 134px 58px;
      gap: 10px;

      div:last-child {
        grid-column: 1/3;
      }
    }

    &.column-three {
      h1 {
        font-size: 18px;
        margin-bottom: 12px;
      }

      p {
        font-size: 8px;
        line-height: 10px;

        ul {
          margin-top: 4px;
        }
      }
    }
  }

}


</style>